import React from "react"

import { withInvalid } from "./withInvalid"
import { Layout } from "../Layout/Layout"
import { Description } from "../Layout/LayoutStyles"

export const Invalid = withInvalid(
  ({ title, description, image }): JSX.Element => (
    <Layout title={title} image={image}>
      <Description>{description}</Description>
    </Layout>
  )
)
