import React from "react"
import { graphql } from "gatsby"

import { Invalid as Page } from "../../components/Account/Invalid/Invalid"

export const query = graphql`
  query {
    page: sanityPageAccountInvalidToken {
      title
      description
      image: _rawImage(resolveReferences: { maxDepth: 2 })
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
    }
  }
`

const Component = ({ data, ...props }) => <Page {...props} {...data} />
export default Component
